<template>
    <div class="w-100">
        <div class="shipload-header">
            <h1 class="pb-3" @click="back"> 
                <span :class="{'text-decoration-underline hoverable': chosen && shiploadOption}">{{ $tc('navigation.shipload_invoicing', 2)}}</span>
                <span v-if="chosen && shiploadOption"> &gt; {{ shiploadOption.name }}</span>
            </h1>
            <div class="d-flex">
                <div class="form-group search w-100">
                    <i class="fa fa-search" aria-hidden="true"></i>
                    <input class="form-control" v-model="search" @keypress.enter="filterRows" @change="filterRows" :placeholder="$t('overview.search')" autocomplete="off">
                </div>
            </div>
            <ul class="nav nav-tabs justify-content-end" id="myTab" role="tablist" v-if="chosen">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="customer-tab" data-bs-toggle="tab" data-bs-target="#customer" type="button" role="tab" aria-controls="customer" aria-selected="true" @click="setTab('customer')">{{$t('navigation.customers')}}</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="repr-tab" data-bs-toggle="tab" data-bs-target="#repr" type="button" role="tab" aria-controls="repr" @click="setTab('repr')">{{$t('overview.representative')}}</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="company-tab" data-bs-toggle="tab" data-bs-target="#company" type="button" role="tab" aria-controls="company" @click="setTab('company')">{{$t('crm.company')}}</button>
                </li>
            </ul>
        </div>
        <div :style="{'margin-top': chosen ? '13.25rem' : '10rem'}"></div>
        <!--<button class="btn btn-primary" type="button" @click="editItem('repr', {})" data-bs-toggle="modal" data-bs-target="#formModal">
            {{ $t('accounting.proceed_invoice') }}
        </button>-->
        <div v-if="loading"> {{ $t('form.please_wait') }} </div>
        <div v-else-if="!voyages.hasVoyages"> {{ $t('form.no_found', [$tc(chosen ? 'navigation.bills_of_lading' : 'navigation.voyages', 2)]) }} </div>

        <template v-else-if="chosen">
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="customer" role="tabpanel" aria-labelledby="customer-tab">
                    <div v-for="(port, subindex) in voyages.customer" :key="subindex" v-show="!port.hidden">
                        <h6 class="my-3 fw-bold"> <i class="fa fa-location-dot"></i> <span class="text-capitalize">{{port.port_of_loading_name}}</span>, <span class="text-capitalize">{{port.pol_country_name}}</span> </h6>

                        <div class="row fw-bold" v-show="port.visible">
                            <div class="col-auto" style="width: 40px">
                                <input class="form-check-input optional" :id="'unit-' + subindex" v-model="port.is_all_active" type="checkbox" @change="checkActive(port, null, $event)" />
                            </div>
                            <div class="col-auto col-form-label-sm sortable" :class="{'selected-header': port.sorter.column == 'number'}" @click="sort('customer', 'number', port)" style="width: calc(25% - 40px)"> 
                                B/L <span :data-sort="port.sorter.direction"></span>
                            </div>
                            <div class="col-3 col-form-label-sm sortable" :class="{'selected-header': port.sorter.column == 'shipper'}" @click="sort('customer', 'shipper', port)"> 
                                {{ $t('operations.shipper') }} <span :data-sort="port.sorter.direction"></span>
                            </div>
                            <div class="col-3 col-form-label-sm sortable" :class="{'selected-header': port.sorter.column == 'office'}" @click="sort('customer', 'office', port)"> 
                                {{ $t('crm.company') }} <span :data-sort="port.sorter.direction"></span>
                            </div>
                            <div class="col-1p5 col-form-label-sm sortable" :class="{'selected-header': port.sorter.column == 'port_of_loading_name'}" @click="sort('customer', 'port_of_loading_name', port)">
                                POL <span :data-sort="port.sorter.direction"></span>
                            </div>
                            <div class="col-1p5 col-form-label-sm sortable" :class="{'selected-header': port.sorter.column == 'port_of_discharge_name'}" @click="sort('customer', 'port_of_discharge_name', port)">
                                POD <span :data-sort="port.sorter.direction"></span>
                            </div>
                        </div>
                        <div class="job-block" >
                            <label v-for="(bl) in port.bls" :key="bl.id" class="row border-top py-1 hoverable" :for="'unit-' + bl.id" v-show="!bl.hidden">
                                <div class="col-auto" style="width: 40px">
                                    <input class="form-check-input optional" type="checkbox" :id="'unit-' + bl.id" v-model="bl.is_active" @change="checkActive(port, bl)" :disabled="bl.hidden" />
                                </div>
                                <div class="col-auto" style="width: calc(25% - 40px)"> {{ bl.number }} </div>
                                <div class="col-3"> {{ bl.shipper }} </div>
                                <div class="col-3"> {{ bl.office }} </div>
                                <div class="col-1p5"> {{ capitalize(bl.port_of_loading_name) }} </div>
                                <div class="col-1p5"> {{ capitalize(bl.port_of_final_destination_name || bl.port_of_discharge_name) }} </div>
                            </label>
                            <div v-show="!port.visible"> {{ $t('form.no_found', [$tc('navigation.bills_of_lading', 2)]) }} </div>
                        </div>
                        <div class="row invoice-btn" :style="{'z-index': (100 - subindex)}" v-show="port.visible">
                            <div class="col-auto mt-2">
                                <button class="btn btn-primary" type="button" @click="editItem('customer', port)" :disabled="!port.is_active" data-bs-toggle="modal" data-bs-target="#formModal">
                                    {{ $t('accounting.proceed_invoice') }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-show="voyages.customerLength == 0"> {{ $t('form.no_found', [$tc('navigation.bills_of_lading', 2)]) }} </div>
                </div>
                <div class="tab-pane fade show" id="repr" role="tabpanel" aria-labelledby="repr-tab">
                    <div class="row fw-bold">
                        <div class="col-3 col-form-label-sm sortable" :class="{'selected-header': voyages.repr.sorter.column == 'port_of_discharge'}" @click="sort('repr', 'port_of_discharge', voyages.repr)"> 
                            {{ $t('sales.discharge_ports') }} <span :data-sort="voyages.repr.sorter.direction"></span>
                        </div>
                        <div class="col-3 col-form-label-sm sortable" :class="{'selected-header': voyages.repr.sorter.column == 'ports_of_loading'}" @click="sort('repr', 'ports_of_loading', voyages.repr)"> 
                            {{ $t('sales.loading_ports') }} <span :data-sort="voyages.repr.sorter.direction"></span>
                        </div>
                        <div class="col-3 col-form-label-sm sortable" :class="{'selected-header': voyages.repr.sorter.column == 'collect'}" @click="sort('repr', 'collect', voyages.repr)"> 
                            {{ $t('operations.collect_amount') }} <span :data-sort="voyages.repr.sorter.direction"></span>
                        </div>
                    </div>
                    <div class="job-block" >
                        <label v-for="(bl) in voyages.repr.bls" :key="bl.id" class="row border-top py-1" :for="'unit-' + bl.id" v-show="!bl.hidden">
                            <div class="col-3 pt-1"> {{ bl.port_of_discharge }} </div>
                            <div class="col-3 pt-1"> {{ bl.port_of_loading }} </div>
                            <div class="col-3 pt-1"> USD {{ formatCurrency(bl.collect) }} </div>
                            <div class="col-3 text-end">
                                <button class="btn btn-sm btn-primary" type="button" @click="editItem('repr', bl)" data-bs-toggle="modal" data-bs-target="#formModal">
                                    {{ $t('accounting.proceed_invoice') }}
                                </button>
                            </div>
                        </label>
                    </div>
                    <div v-show="voyages.repr.bls.length == 0"> {{ $t('form.no_found', [$tc('navigation.bills_of_lading', 2)]) }} </div>
                </div>
                <div class="tab-pane fade show" id="company" role="tabpanel" aria-labelledby="company-tab">
                    <div v-for="(port, subindex) in voyages.company" :key="subindex" v-show="!port.hidden">
                        <h6 class="my-3 fw-bold"> <i class="fa fa-location-dot"></i> <span class="text-capitalize">{{port.office}}</span></h6>

                        <div class="row fw-bold" v-show="port.visible">
                            <div class="col-auto" style="width: 40px">
                                <input class="form-check-input optional" :id="'unit-' + subindex" v-model="port.is_all_active" type="checkbox" @change="checkActive(port, null, $event)" />
                            </div>
                            <div class="col-auto col-form-label-sm sortable" :class="{'selected-header': port.sorter.column == 'pol'}" @click="sort('company', 'pol', port)" style="width: calc(25% - 40px)"> 
                                {{ $t('sales.loading_ports') }} <span :data-sort="port.sorter.direction"></span>
                            </div>
                            <div class="col-3 col-form-label-sm sortable" :class="{'selected-header': port.sorter.column == 'pod'}" @click="sort('company', 'pod', port)"> 
                                {{ $t('sales.discharge_ports') }} <span :data-sort="port.sorter.direction"></span>
                            </div>
                            <div class="col-3 col-form-label-sm sortable" :class="{'selected-header': port.sorter.column == 'bls'}" @click="sort('company', 'bls', port)"> 
                                {{ $tc('navigation.bills_of_lading', 2) }} <span :data-sort="port.sorter.direction"></span>
                            </div>
                        </div>
                        <div class="job-block" >
                            <label v-for="(bl) in port.bls" :key="bl.id" class="row border-top py-1 hoverable" :for="'unit-' + bl.id" v-show="!bl.hidden">
                                <div class="col-auto" style="width: 40px">
                                    <input class="form-check-input optional" type="checkbox" :id="'unit-' + bl.id" v-model="bl.is_active" @change="checkActive(port, bl)" :disabled="bl.hidden" />
                                </div>
                                <div class="col-auto" style="width: calc(25% - 40px)"> {{ bl.pol }} </div>
                                <div class="col-3"> {{ bl.pod }} </div>
                                <div class="col-3"> {{ bl.bls }} </div>
                            </label>
                            <div v-show="!port.visible"> {{ $t('form.no_found', [$tc('navigation.bills_of_lading', 2)]) }} </div>
                        </div>
                        <div class="row invoice-btn" :style="{'z-index': (100 - subindex)}" v-show="port.visible">
                            <div class="col-auto mt-2">
                                <button class="btn btn-primary" type="button" @click="editItem('company', port)" :disabled="!port.is_active" data-bs-toggle="modal" data-bs-target="#formModal">
                                    {{ $t('accounting.proceed_invoice') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div v-else v-for="option in options" :key="option.id" v-show="!option.hidden">
            <h6 class="my-3 fw-bold"> <i class="fa fa-building"></i>&ensp;{{ option.office.relation.name }} </h6>

            <div class="row fw-bold">
                <div class="col-2 col-form-label-sm sortable" :class="{'selected-header': option.sorter.column == 'number'}" @click="sort('overview', 'number', option)"> 
                    Project <span :data-sort="option.sorter.direction"></span>
                </div>
                <div class="col-4 col-form-label-sm sortable" :class="{'selected-header': option.sorter.column == 'name'}" @click="sort('overview', 'name', option)"> 
                    {{ $t('navigation.voyages') }} <span :data-sort="option.sorter.direction"></span>
                </div>
                <div class="col-3 col-form-label-sm sortable" :class="{'selected-header': option.sorter.column == 'pending_bls'}" @click="sort('overview', 'pending_bls', option)"> 
                    Bs/L pending <span :data-sort="option.sorter.direction"></span>
                </div>
                <div class="col-3 col-form-label-sm sortable" :class="{'selected-header': option.sorter.column == 'total_bls'}" @click="sort('overview', 'total_bls', option)">
                    Bs/L total <span :data-sort="option.sorter.direction"></span>
                </div>
            </div>
            <div class="job-block" >
                <template v-for="project in option.projects" :key="project.id">
                    <div class="row border-top py-1 hoverable" @click="getIndex(project, option.id)" v-show="!project.hidden && project.pending_bls > 0">
                        <div class="col-2"> {{ project.number }} </div>
                        <div class="col-4"> {{ project.name }} </div>
                        <div class="col-3"> {{ project.pending_bls }} </div>
                        <div class="col-3"> {{ project.total_bls }} </div>
                    </div>
                </template>
            </div>
        </div>
    </div>

    <FormPopup v-on:save="save" v-on:del="del" :itemId="null" :itemName="item ? item.name : null" :moduleName="$t('navigation.invoices')" :loading="false" 
               modalSize="modal-xl" modalHeight="90%" modalWidth="1400px" :isCopy="false" :itemStatus="null" :dontRemoveHash="true" ref="FormRef">
        <slot name='form'>
            <ReadyInvoiceForm ref="ItemForm" :itemData="item" />
        </slot>
        <template #modal-footer>
            <FormItem type="download" id="pdfbutton" :label="$t('accounting.show_pdf')" :service="itemFormRef ? itemFormRef.showPDF : null" />
        </template>
    </FormPopup>

</template>

<script>
    import MainportProjectService from '@/services/MainportProjectService';
    import voyageService from '@/services/VoyageService';
    import invoiceService from '@/services/InvoiceService';
    import FormPopup from '@/components/FormPopup.vue';
    import ReadyInvoiceForm from '@/components/invoice/InvoiceFormNew.vue';

    export default {
        components: {
            FormPopup,
            ReadyInvoiceForm
        },
        data() {
            return {
                options: {},
                optionsArray: [],
                voyages: {customer: [], repr: {}, company: [], hasVoyages: true},
                invalidJobs: [],
                item: null,
                itemLoading: false,
                itemFormRef: {},
                loading: false,
                chosen: false,
                search: '',
                tab: 'customer',
                shiploadOption: null,
                shiploadOptionOfficeId: null
            }
        },
        methods: {
            getIndex(option, officeId) {
                if(option) this.shiploadOption = option;
                if(officeId) this.shiploadOptionOfficeId = officeId;
                if(!this.shiploadOption || !this.shiploadOptionOfficeId) return;
                this.loading = true;
                this.chosen = true;
                voyageService.getShiploadInvoiceVoyages(this.shiploadOption.id).then(response => {
                    this.loading = false;
                    this.voyages = {customer: [], repr: {}, company: [], hasVoyages: false, customerLength: 0};
                    let voyages = {customer: {}, repr: {}, company: {}, customerLength: 0};
                    let podpols = {};
                    this.search = '';
                    for(let id in this.options){ this.options[id].hidden = false; }
                    response.data.forEach(dat => {
                        this.voyages.hasVoyages = true;
                        const polname = this.capitalize(dat.port_of_loading_name);
                        const polcountryname = this.capitalize(dat.pol_country_name);
                        const podname = this.capitalize(dat.port_of_final_destination_name || dat.port_of_discharge_name);
                        const podcountryname = this.capitalize(dat.pofd_country_name || dat.pod_country_name);
                        const port_of_discharge_name = dat.port_of_final_destination_name || dat.port_of_discharge_name;
                        const port_of_discharge_code = dat.port_of_final_destination_code || dat.port_of_discharge_code;
                        if(voyages.company[dat.office] === undefined){
                            voyages.company[dat.office] = {};
                        }
                        const code = port_of_discharge_code + '-' + dat.port_of_loading_code;
                        if(voyages.repr[code] === undefined && !dat.checkedRepresentative){
                            voyages.repr[code] = {};
                            voyages.repr[code].is_active = false;
                            voyages.repr[code].count = 0;
                            voyages.repr[code].port_of_discharge = podname + ', ' + podcountryname;
                            voyages.repr[code].port_of_loading = polname + ', ' + polcountryname;
                            voyages.repr[code].ports_of_loading = "";
                            voyages.repr[code].collect = 0;
                            voyages.repr[code].ids = [];
                            voyages.repr[code].pols = [];
                            voyages.repr[code].voyages = {};
                        }
                        if(voyages.company[dat.office][port_of_discharge_name] === undefined && !dat.checkedNmtcompany){
                            voyages.company[dat.office][port_of_discharge_name] = {};
                        }
                        if(voyages.customer[dat.port_of_loading_code] === undefined && !dat.checked){
                            voyages.customer[dat.port_of_loading_code] = {};
                            voyages.customer[dat.port_of_loading_code].is_active = false;
                            voyages.customer[dat.port_of_loading_code].is_all_active = false;
                            voyages.customer[dat.port_of_loading_code].active_count = 0;
                            voyages.customer[dat.port_of_loading_code].port_of_loading_name = polname;
                            voyages.customer[dat.port_of_loading_code].pol_country_name = polcountryname;
                            voyages.customer[dat.port_of_loading_code].for_customer = dat.for_customer;
                            voyages.customer[dat.port_of_loading_code].bls = [];
                            voyages.customerLength++;
                        }
                        const vcode = (polname + ' - ' + podname);
                        if(!dat.checkedRepresentative && voyages.repr[code]){
                            if(voyages.repr[code].voyages[vcode] === undefined){
                                voyages.repr[code].voyages[vcode] = dat.totalcollect;
                            }
                            else{
                                voyages.repr[code].voyages[vcode] += dat.totalcollect;
                            }
                        }
                        if(podpols[port_of_discharge_code] === undefined){
                            podpols[port_of_discharge_code] = {};
                        }
                        if(podpols[port_of_discharge_code][dat.port_of_loading_code] === undefined && !dat.checkedRepresentative){
                            voyages.repr[code].count++;
                            if(voyages.repr[code].count == 1){
                                voyages.repr[code].ports_of_loading = dat.port_of_loading_code;
                            }
                            else if(voyages.repr[code].count < 4){
                                voyages.repr[code].ports_of_loading += (", " + dat.port_of_loading_code);
                            }
                            else if(voyages.repr[code].count == 4){
                                voyages.repr[code].ports_of_loading += " +1";
                            }
                            else if(voyages.repr[code].count > 4){
                                voyages.repr[code].ports_of_loading = voyages.repr[code].ports_of_loading.replace(/\+.+$/, '+' + (voyages.repr[code].count - 3));
                            }
                            podpols[port_of_discharge_code][dat.port_of_loading_code] = 1;
                            voyages.repr[code].pols.push(polname + ', ' + polcountryname);
                        }
                        if(!dat.checkedNmtcompany && voyages.company[dat.office][port_of_discharge_name] && voyages.company[dat.office][port_of_discharge_name][dat.port_of_loading_name] === undefined){
                            voyages.company[dat.office][port_of_discharge_name][dat.port_of_loading_name] = {count: 0, ids: [], polcountry: polcountryname, podcountry: podcountryname, vins: []};
                        }
                        let newdat = dat;
                        dat.is_active = false;
                        if (dat.shipper) {
                            dat.shipper = dat.shipper.split('\n')[0];
                        }
                        if(!dat.checkedRepresentative && voyages.repr[code]){
                            voyages.repr[code].ids.push(dat.id);
                            voyages.repr[code].collect += (dat.totalcollect ? dat.totalcollect : 0);
                        }

                        if(dat.officeid == this.shiploadOptionOfficeId && !dat.checked){
                            voyages.customer[dat.port_of_loading_code].bls.push(newdat);
                        }
                        else if(dat.officeid != this.shiploadOptionOfficeId && !dat.checkedNmtcompany && voyages.company[dat.office][port_of_discharge_name]){
                            voyages.company[dat.office][port_of_discharge_name][dat.port_of_loading_name].ids.push(dat.id);
                            voyages.company[dat.office][port_of_discharge_name][dat.port_of_loading_name].count += 1;
                            voyages.company[dat.office][port_of_discharge_name][dat.port_of_loading_name].vins.push(dat.number);
                        }
                    });

                    for(let dat in voyages.customer){
                        if(voyages.customer[dat].bls.length > 0){
                            voyages.customer[dat].bls.sort((a,b) => a.shipper > b.shipper ? 1 : -1);
                            voyages.customer[dat].visible = voyages.customer[dat].bls.length > 0;
                            voyages.customer[dat].sorter = {column: 'shipper', direction: 1};
                            this.voyages.customer.push(voyages.customer[dat]);
                        }
                    }

                    this.voyages.repr.bls = [];
                    for(let dat in voyages.repr){
                        if(voyages.repr[dat].collect > 0){
                            this.voyages.repr.bls.push(voyages.repr[dat]);
                        }
                    }
                    this.voyages.repr.sorter = {column: 'port_of_discharge', direction: 1};
                    this.voyages.repr.bls.sort((a,b) => a.port_of_discharge > b.port_of_discharge ? 1 : -1);
                    
                    for(let office in voyages.company){
                        let newsize = this.voyages.company.push({
                            office: office,
                            sorter: {column: 'pol', direction: 1},
                            is_active: false,
                            is_all_active: false,
                            active_count: 0,
                            bls: []
                        });
                        for(let pod in voyages.company[office]){
                            for(let pol in voyages.company[office][pod]){
                                if(voyages.company[office][pod][pol].count > 0){
                                    this.voyages.company[newsize - 1].bls.push({
                                        pol: this.capitalize(pol),
                                        pod: this.capitalize(pod),
                                        polcountry: voyages.company[office][pod][pol].polcountry,
                                        podcountry: voyages.company[office][pod][pol].podcountry,
                                        is_active: false,
                                        bls: voyages.company[office][pod][pol].count,
                                        ids: voyages.company[office][pod][pol].ids,
                                        vins: voyages.company[office][pod][pol].vins,
                                        id: pol + pod
                                    });
                                    this.voyages.company[newsize - 1].bls.sort((a,b) => a.pol > b.pol ? 1 : -1);
                                    this.voyages.company[newsize - 1].visible = this.voyages.company[newsize - 1].bls.length > 0;
                                }
                            }
                        }
                    }

                    this.voyages.customer.sort((a, b) => a.port_of_loading_name > b.port_of_loading_name ? 1 : -1);
                    this.voyages.company.sort((a, b) => a.office > b.office ? 1 : -1);
                    this.voyages.customerLength = voyages.customerLength;
                    window.location.hash = this.shiploadOption.id;
                    console.log(voyages);
                }).catch(error => {
                    console.log('error', error)
                });
            },
            editItem(type, port) {
                this.itemLoading = true;
                let blIds = [];
                if(type == 'repr'){
                    blIds = [...port.ids];
                }
                else{
                    port.bls.forEach(bl => {
                        if (bl.is_active) {
                            if(type == 'customer'){
                                blIds.push(bl.id);
                            }
                            else{
                                bl.ids.forEach(id => blIds.push(id));
                            }
                        }
                    });
                }
                const selectedOption = this.optionsArray.find(opt => opt.id == this.shiploadOption.id);
                voyageService.getInvoiceReadyVoyageShipload(this.shiploadOption.id, blIds).then(response => {
                    let units = response.data;
                    units.sort((a, b) => {
                        if(a.number > b.number) return 1;
                        return -1;
                    });
                    selectedOption.units = units;
                    let polpods = false;
                    if(port.pols) polpods = port.pols;
                    if(type == 'company'){
                        let allpols = [];
                        let allpods = [];
                        port.bls.filter(v => v.is_active).forEach(bl => {
                            if(!allpols.includes(bl.pol + ', ' + bl.polcountry))
                                allpols.push(bl.pol + ', ' + bl.polcountry);
                            if(!allpods.includes(bl.pod + ', ' + bl.podcountry))
                                allpods.push(bl.pod + ', ' + bl.podcountry);
                        });
                        selectedOption.port_name = allpols.join(" - ");
                        selectedOption.pod_name = allpods.join(" - ");
                    }
                    else{
                        selectedOption.port_name = polpods ? polpods.join(" - ") : port.port_of_loading_name + ', ' + port.pol_country_name;
                    }
                    if(type != 'customer'){
                        selectedOption.extraDetails = port;
                        if(type == 'company'){
                            selectedOption.extraDetails.bls.forEach(bl => bl.vins.sort());
                        }
                    }
                    let pods = [];
                    units.forEach(unit => {
                        let value = (unit.port_of_final_destination_name || unit.port_of_discharge_name).toLowerCase();
                        value = value.charAt(0).toUpperCase() + value.slice(1);
                        let country = (unit.pofd_country_name || unit.pod_country_name).toLowerCase();
                        value = value + ', ' + country.charAt(0).toUpperCase() + country.slice(1);
                        let headlineArr = [];
                        unit.units.forEach(newUnit => {
                            //eslint-disable-next-line
                            const descString = (newUnit.stateid == 1 ? 'NEW ' : 'USED ') + (newUnit.rightdetailscolumn ? newUnit.rightdetailscolumn.replaceAll('\n', ' ') : '').replace(/[\u0000-\u001F\u007F-\u009F]/g, "");
                            const prevArr = headlineArr.find(ha => ha.name == descString);
                            if(prevArr){
                                prevArr.amount++;
                            }
                            else{
                                headlineArr.push({amount: 1, name: descString});
                            }
                        });
                        unit.headline = headlineArr.map(ha => (ha.amount + ' ' + ha.name)).join(", ");
                        if(!pods.includes(value)){
                            pods.push(value);
                        }
                        if(type == 'company'){
                            let foundED = selectedOption.extraDetails.bls.find(ed => ed.pol.toUpperCase() == unit.port_of_loading_name && ed.pod.toUpperCase() == unit.port_of_discharge_name);
                            if(foundED){
                                if(!foundED.totalcollect) foundED.totalcollect = 0;
                                foundED.totalcollect += unit.totalcollect;
                            }
                        }
                    });
                    if(type != 'company'){
                        selectedOption.pod_name = pods.join(' - ');
                    }
                    selectedOption.bl_ids = blIds;
                    this.itemFormRef.formActive = true;
                    this.itemFormRef.shiploadMode = type;
                    console.log(JSON.parse(JSON.stringify(selectedOption)));
                    this.itemFormRef.setData(selectedOption);
                }).catch(error => {
                    this.toastError(error)
                    console.log('error', error)
                });
            },
            sort(type, column, port){
                const sub = type == 'overview' ? 'projects' : 'bls';
                port.sorter.direction = port.sorter.direction == 1 && port.sorter.column == column ? -1 : 1;
                port.sorter.column = column;
                port[sub].sort((a, b) => {
                    return a[column] > b[column] ? port.sorter.direction : -port.sorter.direction;
                });
            },
            filterRows(){
                const regex = new RegExp("^" + this.search.replaceAll(' ', '\n'), "mi");
                const regexAnywhere = new RegExp(this.search, "i");
                let nothingFoundInPage = true
                if(this.chosen){
                    let list = this.tab == 'repr' ? this.voyages[this.tab].bls : this.voyages[this.tab];
                    for(let port of list){
                        let nothingFoundInPort = true;
                        if(this.tab == 'customer'){
                            for(let bl of port.bls){
                                bl.hidden = !regexAnywhere.test(bl.number) && !regex.test(bl.shipper.replaceAll(' ', '\n') + '\n' + (bl.office ? bl.office.replaceAll(' ', '\n') : '')) && !regex.test(bl.port_of_loading_name) && !regex.test(bl.port_of_discharge_name);
                                if(!bl.hidden) nothingFoundInPort = false;
                            }
                            port.hidden = nothingFoundInPort;
                        }
                        else if(this.tab == 'repr'){
                            port.hidden = !regex.test(port.port_of_discharge.replaceAll(', ', '\n')) && !regex.test(port.ports_of_loading.replaceAll(', ', '\n'));
                            if(!port.hidden) nothingFoundInPort = false;
                        }
                        else if(this.tab == 'company'){
                            for(let bl of port.bls){
                                bl.hidden = !regexAnywhere.test(bl.pol) && !regexAnywhere.test(bl.pod);
                                if(!bl.hidden) nothingFoundInPort = false;
                            }
                            port.hidden = nothingFoundInPort;
                        }
                        if(!nothingFoundInPort) nothingFoundInPage = false;
                    }
                }
                else{
                    for(let id in this.options){
                        let nothingFoundInPort = true;
                        for(let project of this.options[id].projects){
                            project.hidden = !regexAnywhere.test(project.number) && !regex.test(project.name.replaceAll(' ', '\n'));
                            if(!project.hidden) nothingFoundInPort = false;
                        }
                        this.options[id].hidden = nothingFoundInPort;
                        if(!nothingFoundInPort) nothingFoundInPage = false;
                    }
                }
                this.voyages.hasVoyages = !nothingFoundInPage;
            },
            capitalize(value){
                return value[0] + value.substring(1).toLowerCase();
            },
            setTab(tab){
                this.tab = tab;
                this.search = '';
                if(tab == 'customer' || tab == 'company'){
                    for(let port of this.voyages[tab]){
                        port.hidden = false;
                        for(let bl of port.bls){
                            bl.hidden = false;
                        }
                    }
                }
            },
            setActive(item) {
                if (item.is_active === undefined)
                    item.is_active = true;
            },
            checkActive(port, bl, e) {
                const filteredBLs = port.bls.filter(bl => bl.hidden != true);
                const length = filteredBLs.length;
                if(bl == null){
                    if(!e.target.checked){
                        port.active_count = 0;
                        filteredBLs.forEach(bl => bl.is_active = false);
                        port.is_active = false;
                    }
                    else{
                        port.active_count = length;
                        filteredBLs.forEach(bl => bl.is_active = true);
                        port.is_active = true;
                    }
                }
                else if(!bl.hidden){
                    if(bl.is_active){
                        port.active_count++;
                    }
                    else{
                        port.active_count--;
                    }
                    port.is_active = port.active_count > 0;
                    port.is_all_active = port.active_count == length;
                }
            },
            save() {
                let validate = this.itemFormRef.validate();
                if (!validate) return false;
                let item = this.itemFormRef.getData();

                if(item.id){
                    invoiceService.update(item).then(() => {
                        //this.getIndex(this.shiploadOption, this.shiploadOptionOfficeId);
                        this.loadProjects();
                        document.querySelector("#modal-close-button").click();
                    }).catch(error => {
                        this.toastError(error)
                    })
                }
                else{
                    invoiceService.store(item).then(() => {
                        //this.getIndex(this.shiploadOption, this.shiploadOptionOfficeId);
                        this.loadProjects();
                        document.querySelector("#modal-close-button").click();
                    }).catch(error => {
                        this.toastError(error)
                    });
                }
            },
            del() {
                console.log('');
            },
            back(manipHash = true){
                this.chosen = false;
                this.voyages.hasVoyages = true;
                this.search = '';
                if(manipHash) window.location.hash = '';
                this.$nextTick(() => this.filterRows());
            },
            loadProjects(){
                MainportProjectService.index().then(response => {
                    this.options = {};
                    this.optionsArray = response.data;
                    this.voyages.hasVoyages = response.data.length > 0;
                    response.data.forEach((dat, datindex) => {
                        if(dat.pending_bls <= 0 && dat.created_at < "2024-08-01") return;
                        if(dat.total_bls === 0) return;
                        dat.name = `${dat.voyage.vessel.name} ${dat.voyage.number}`;
                        if(this.options[dat.office_id] === undefined){
                            this.options[dat.office_id] = {
                                id: dat.office_id,
                                officeid: dat.office.legacy_shipload_id,
                                office: dat.office,
                                sorter: {column: 'name', direction: 1},
                                projects: []
                            }
                        }
                        this.options[dat.office_id].projects.push(dat);
                    });
                    this.search = '';
                    for(let id in this.options){
                        this.options[id].projects.sort((a, b) => a.name > b.name ? 1 : -1);
                    }
                    if(window.location.hash){
                        const option = this.optionsArray.find(opt => opt.id == window.location.hash.replace('#', ''));
                        if(option) this.getIndex(option, option.id);
                    }
                }).catch(error => {
                    console.log('error', error)
                });
            }
        },
        mounted() {
            this.itemFormRef = this.$refs.ItemForm;
            //eslint-disable-next-line
            const parent = this;
            window.addEventListener("hashchange", function(e) {
                if(!window.location.hash && parent.$route.name == "Shipload Invoicing"){ 
                    parent.back(false);
                }
            });
            this.loadProjects();
        }
    }
</script>
<style scoped>
.sortable:hover, .hoverable:hover{
    background-color: #eee;
    cursor: pointer
}
.selected-header{
  background-color: #cef !important
}
.selected-header > [data-sort="1"]::before{
  content: "\2193"
}
.selected-header > [data-sort="-1"]::before{
  content: "\2191"
}
.shipload-header{
    position: fixed;
    top: 0;
    left: 18rem;
    width: calc(100% - 18rem);
    padding: 15px;
    background-color: white;
    z-index: 120
}
.invoice-btn{
    position: sticky;
    bottom: 0;
    background-color: white;
    padding-bottom: 4px;
}
.col-1p5{
    flex: 0 0 auto;
    width: 12%;
}
</style>